import data from "./Events-Latest.json"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './schedule.css';
import ScheduleLeft from "./ScheduleLeft";
import { colors } from "@mui/material";
const Schedule =()=>{
  return (
    <div className="sched_main">
      <div className="blur-bg">
        <h1 className="main-heading">Past Events</h1>
        {data.map( item =>{return(<ScheduleLeft title1={item.title} date1={item.Day_Time} place1={item.Location} image ={item.imagesrc} pos={item.pos?item.pos:"right"}/>);} )}
      </div>
    </div>
    )
}
export default Schedule;
