import React from "react";
import "./footer.css";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { FiChevronRight } from "react-icons/fi";
import { FiSend } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import bbb from "./background.jpg"

const Footer = () => {
  const history = useHistory();

  const scrollSmoothTo= async (elementId) => {
    await history.push("/");
    var element = document.getElementById(elementId);
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  return (
    <section className="footer">
      <div className="videoDiv">
        <img src={bbb} alt=""></img>
        {/* <video src={sea} loop autoPlay muted type="video/mp4"></video> */}
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          
          {/* <div className="inputDiv flex">
            <input type="text" placeholder="Enter Email Address" />
            <button className="btn flex" type="submit">
              SEND <FiSend className="icon" />
            </button>
          </div> */}
        </div>
     

      <div className="footerCard flex">
        <div className="footerIntro flex">
        <div className="text">
            <small>IIT Bhubaneswar</small>
            <h2>Alma Fiesta 2024</h2>
          </div>
          {/* <div className="footerParagraph">
            The Annual Socio-Cultural Fest of IIT Bhubaneswar.<br/>
            Gear up for the biggest cultural extravaganza of the year!
          </div> */}

          <div className="footerSocials">
            <a href="https://www.youtube.com/@almafiesta"><AiFillYoutube className="icon" /></a>
            <span>      </span>
            <a href="https://www.instagram.com/almafiesta.iitbbs/?hl=en"><AiFillInstagram className="icon"/></a>
          </div>
        </div>
        <div className="footerLinks grid">
          <div className="linkGroup">
            <span className="groupTitle">OUR FEST</span>

            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a href="/">Home</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a onClick={()=>{scrollSmoothTo("events")}}>Events</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a href="/contactus">Contact us</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a href="/schedule">Schedule</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a onClick={()=>{scrollSmoothTo("about us")}}>About us</a>
            </li>
          </div>
          <div className="linkGroup">
            <span className="groupTitle">LEGAL</span>

            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a onClick={()=> history.push("/privacy-policy")} >Privacy Policy</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a onClick={()=> history.push("/terms-and-conditions")}>Terms and condition</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a onClick={()=> history.push("/cancellation-and-refund")}>Refund</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a onClick={()=> history.push("/shipping-delivery")}>Shipping and delivery</a>
            </li>
            <li className="footerList flex">
              <FiChevronRight className="icon" />
              <a onClick={()=> history.push("/contact-us")}>Contact us</a>
            </li>
          </div>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Footer;
