import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./schedule.css";
const ScheduleLeft = (props) => {
  return (
    <div className="main">
      <VerticalTimeline lineColor="#3498DB " style={{ height: "99%" }}>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          position={props.pos}
          contentStyle={{
            // background: "#E5E7E9",
            color: "#080112",
            borderRadius: 15,
            
          }}
          contentArrowStyle={{ borderRight: "20px solid  rgb(89, 161, 186)" }}
          date={props.date1}
          iconStyle={{ backgroundImage: `URL(${props.image})`, color: "#fff",backgroundSize:"cover" }}
        >
          <h3 className="vertical-timeline-element-title">{props.title1}</h3>
          <h5 className="vertical-timeline-element-subtitle">{props.place1}</h5>
          {/* <p>{}</p> */}
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};
export default ScheduleLeft;
