import "./schedule.css"
import Schedule from "./Schedule";

const SchedulePage = () => {
  return (
    <div className="App">
      {/* <div className="containerCS">
        <h1>
          Schedule<br></br>
          
        </h1>
        <h2>
        Coming Soon...
        </h2>
      </div> */}
      <Schedule />
    </div>
  )
}

export default SchedulePage;