import React from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";


function CancellationRefundPolicy(){
    return (
        <>
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4 mt-4 d-flex justify-content-center">
        <MDBBreadcrumbItem
          style={{ fontFamily: "CenturyGothic", fontSize: "1.5rem" }}
        >
          Cancellation & Refund Policy
        </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4 mt-4 d-flex justify-content-center">
        <MDBBreadcrumbItem
          style={{ fontFamily: "CenturyGothic", fontSize: "1rem" }}
        >
          <b><u>Last updated on Mar 4th 2023:   </u></b>

          No cancellations & Refunds are entertained
        </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        
        </>
    )
}

export default CancellationRefundPolicy;