import React, { useState, useContext, useEffect } from "react";
import "./navbar.css"; // Consider renaming this file to sidebar.css and updating the styles accordingly
import oldLogo from "../../images/oldLogo.png";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { AccountContext } from "../../Context/AccountProvider";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Button, Stack, useMediaQuery } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { CgProfile } from "react-icons/cg";
import { TbLogout } from "react-icons/tb";
import {
  displayErrorToast,
  displayInfoToast,
  displaySuccessToast,
} from "../../Utils/ToastUtils";
import { FaSkull } from 'react-icons/fa';
import closeBtn from "./closeBtn.png" ;
import almaLogo from "../../images/alma.png";
import curse from '../../images/cursor.png';
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = () => {
  const [active, setActive] = useState(false); // This will control the sidebar's visibility
  const history = useHistory();
  const { account, setAccount } = useContext(AccountContext);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const isDesktopOrLaptop = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      setIsLoggedOut(false);
    } else {
      setIsLoggedOut(true);
    }
  }, [account]);

  const toggleSidebar = () => {
    setActive(!active); // Toggle the visibility of the sidebar
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
        // console.log(credentialResponse);
    
        try {
          const loginResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/user/login`,
            { tokenId: credentialResponse.credential },
            {
              withCredentials: true,
            }
          );
          if (loginResponse.status === 201) {
            displaySuccessToast(`Welcome to Alma Fiesta`, { autoClose: 2000 });
    
            window.localStorage.setItem("jwt", loginResponse.data.jwt);
            window.localStorage.setItem("email", loginResponse.data.user.email);
            setAccount(loginResponse.data.user);
            displayInfoToast("Fill Your Rest Details");
            history.push("/profile");
          } else if (loginResponse.status === 200) {
            window.localStorage.setItem("jwt", loginResponse.data.jwt);
            window.localStorage.setItem("email", loginResponse.data.user.email);
            setAccount(loginResponse.data.user);
            if (loginResponse.data.user.isRegistrationComplete) {
              displaySuccessToast(`Logged in Successfully`, { autoClose: 2000 });
            } else {
              history.push("/profile");
            }
          }
        } catch (e) {
          if (String(e.response.data.error.statusCode).startsWith("4")) {
            return toast.error(e.response.data.message);
          }
          toast.error("There was some error! Please try again later");
        }
        // console.log(account);
        
      };
    
      const handleGoogleLoginFailure = () => {
        toast.error("There was some error! Please try again later");
      };
    
      const handleGoogleLogout = async () => {
        try {
          const logoutResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/user/logout`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
              },
              withCredentials: true,
            }
          );
          displayInfoToast("Logged out Successfully!");
          window.localStorage.clear();
          history.push("/");
          setIsLoggedOut(true);
        } catch (e) {
          if (String(e.response.data.error.statusCode).startsWith("4")) {
            return displayErrorToast(e.response.data.message);
          }
          displayErrorToast("There was some error! Please try again later");
        }
      };
    
      const scrollSmoothTo= async (elementId) => {
        await history.push("/");
        var element = document.getElementById(elementId);
        element.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }
    
      // console.log(isLoggedin,account);
      // console.log(localStorage.getItem("jwt"))

      const handleLogoClick = () => {
        history.push('/');
      };


      return (
        <>



<button className="homeIcon" onClick={handleLogoClick} style={{ cursor: curse }}>
      <img src={almaLogo} alt="Logo"></img> {/* It's good practice to include an alt attribute for images */}
    </button>

          <button className = "menuIcon" onClick={toggleSidebar} style={{ fontSize: '24px', backgroundColor: 'black', color: 'white', border: 'none', padding: '10px 20px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: curse }}>
            <FaSkull />{"...Stab Me"}
          </button>

          <aside className={`sidebar ${active ? "active" : ""}`}>

           <button className="closeIcon" onClick={toggleSidebar} style={{ border: 'none', background: 'none' }}>
              <img src={closeBtn} alt="Icon" style={{ width: '24px', height: '24px'}} />
           </button>

            <ul className="navLists">
              <li ><a href="/">Home</a></li>
              <li ><a onClick={()=>{scrollSmoothTo("aboutus")}}>About Us</a></li>
              <li ><a onClick={()=>{scrollSmoothTo("events")}}>Events</a></li>
              <li ><a href="/contactus">Contact Us</a></li>
              <li ><a href="/schedule">Schedule</a></li>
              <li ><a href="/ourTeam">Our Team</a></li>
              <li ><a href="/sponsors">Our Sponsors</a></li>

            </ul>
      {!localStorage.getItem("jwt") ? (
        <GoogleLogin
          style={{ marginLeft: "1rem" }}
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
          useOneTap
        />
      ) : (
        <Stack
          direction={isDesktopOrLaptop ? "row" : "column"}
          spacing={2}
          //className="authButtons"
        >
          <Button
            onClick={() => history.push("/profile")}
            size="small"
            disableElevation
            color="secondary"
            variant="contained"
            startIcon={<CgProfile />}
          >
            Profile
          </Button>
          <Button
            onClick={handleGoogleLogout}
            size="small"
            disableElevation
            color="error"
            variant="contained"
            endIcon={<TbLogout />}
          >
            Logout
          </Button>
        </Stack>
      )}
          </aside>
        </>
      );
};

export default Navbar;

