import { Box, Button, Grid } from "@mui/material";

const EventCardModal = ({ event }) => {
  return (
    <Grid
      container
      style={{
        backgroundColor: "white",
        borderRadius: "20px",
        width: "90vw",
        height: "90vh",
        overflow: "scroll",
      }}
    >
      <Grid item xs={12} sm={4}>
        <img
          src={event.imageSrc}
          alt={event.title}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            overflowY: "hidden",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box
          p={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            {event.title}
          </div>
          <p
            style={{
              fontSize: "1rem",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            {event.description}
          </p>
          <p
            style={{
              fontSize: "1.5rem",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Rules
          </p>
          <ul
            style={{
              fontSize: "1rem",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            {event.rules.map((rule) => {
              return <li>{rule}</li>;
            })}
          </ul>
          <p
            style={{
              fontSize: "1.5rem",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Judging Criteria
          </p>
          <ul
            style={{
              fontSize: "1rem",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            {event.criteria.map((rule) => {
              return <li>{rule}</li>;
            })}
          </ul>
          <p
            style={{
              fontSize: "1.5rem",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Contact: {event.contact}
          </p>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "15rem",
              height: "3rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginTop: "3rem",
              alignSelf: "center",
              justifySelf: "center",
            }}
          >
            Register
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EventCardModal;
