import React from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";


function ContactUs(){
    return (
        <>
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4 mt-4 d-flex justify-content-center">
        <MDBBreadcrumbItem
          style={{ fontFamily: "CenturyGothic", fontSize: "1.5rem" }}
        >
          Contact Us
        </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4 mt-4 d-flex justify-content-center">
        <MDBBreadcrumbItem
          style={{ fontFamily: "CenturyGothic", fontSize: "1rem" }}
        >
          <b><u>Last updated on Oct 13th 2022:   </u></b>

          You may contact us using the information below:

Merchant Legal entity name: Alma Fiesta
Registered Address: Student Gymkhana IIT Bhubaneswar Jajapur ODISHA 755020
Telephone No: 7380778657
E-Mail ID: deepak26071976@gmail.com
        </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        
        </>
    )
}

export default ContactUs;