import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
  margin: 2rem;
  width: 300px;
  height: 450px;
  backdrop-filter: blur(4px);
  background-color: rgba(0,0,0,0.3);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  overflow: hidden;
`;

const Name = styled.h3`
  margin: 0.5rem 0 0;
  color: white;
`;

const Email = styled.p`
  margin: 0.25rem 0;
  color: white;
`;

const Phone = styled.p`
  margin: 0.25rem 0;
  color: white;
`;

const OurTeam = ({ contacts }) => {
  return (
    <div
      style={{
        padding: "6rem 0",
      }}
    >
      <h2 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        Contact Us
      </h2>
      <Grid>
        {contacts.map((contact) => (
          <Card key={contact.email}>
            <Image src={contact.image} alt={contact.name} />
            <Name>{contact.name}</Name>
            <Email>{contact.email}</Email>
            <Phone>{contact.phone}</Phone>
          </Card>
        ))}
      </Grid>
    </div>
  );
};

export default OurTeam;
