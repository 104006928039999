import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// const products = [
//   {
//     name: 'Audio Play',
//     desc: 'Dramatics',
//     price: `100`,
//   },
  // {
  //   name: 'Product 2',
  //   desc: 'Another thing',
  //   price: '$3.45',
  // },
  // {
  //   name: 'Product 3',
  //   desc: 'Something else',
  //   price: '$6.51',
  // },
  // {
  //   name: 'Product 4',
  //   desc: 'Best thing of all',
  //   price: '$14.11',
  // },
//   { name: 'Shipping', desc: '', price: 'Free' },
// ];

// const addresses = ['1 MUI Drive', 'Reactville', 'Anytown', '99999', 'USA'];
// const payments = [
//   { name: 'Card type', detail: 'Visa' },
//   { name: 'Card holder', detail: 'Mr John Smith' },
//   { name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234' },
//   { name: 'Expiry date', detail: '04/2024' },
// ];

export default function Review({paymentType,setPaymentType}) {

  const [amount, setAmount] = React.useState(300);
  
  const handleChange = (event) => {
    
    if(event.target.value === "1"){
      setAmount(300);
    }
    else{
      setAmount(1100);
    }

    setPaymentType(event.target.value);
  };


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Select Scheme
      </Typography>
      <List disablePadding>
        {/* {products.map((product) => ( */}
          {/* <ListItem key={subEventDetails.title} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={subEventDetails.title} secondary={subEventDetails.subtitle} />
            <Typography variant="body2">&#x20B9; {100}</Typography>
          </ListItem> */}
        {/* ))} */}
        <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={paymentType}
        onChange={handleChange}
      >
        <FormControlLabel value={1} control={<Radio />} label="Register without accommodation" />
        <ListItemText className="pl-5" secondary="You will get access to all events and star night for all 3 days of our fest without accommodation" />
        <FormControlLabel value={2} control={<Radio />} label="Register with accommodation" />
        <ListItemText className="pl-5" secondary="You will get access to all events and star night for all 3 days of our fest with accommodation at our hostel premises for 3 days" />
      </RadioGroup>

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography fontSize={18} variant="subtitle1" sx={{ fontWeight: 700 }}>
          &#x20B9; {amount}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping
          </Typography>
          <Typography gutterBottom>John Smith</Typography>
          <Typography gutterBottom>{addresses.join(', ')}</Typography>
        </Grid> */}
        {/* <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}