import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import drams from "./dramatics.jpg";
import lit from "./literature.jpg";
import music from "./music.jpg";
import finearts from "./fineArts.jpg";
import photo from "./photography.jpg";
import dance from "./dance.jpg";
import misc from "./miscellaneous.jpg";
import work from "./workshop.jpg";
import quiz from "./quiz.jpg";

const ImageBackdrop = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "#000",
  opacity: 0.5,
  transition: theme.transitions.create("opacity"),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  display: "block",
  padding: 0,
  borderRadius: 0,
  height: "40vh",
  [theme.breakpoints.down("md")]: {
    width: "100% !important",
    height: 100,
  },
  "&:hover": {
    zIndex: 1,
  },
  "&:hover .imageBackdrop": {
    opacity: 0.15,
  },
  "&:hover .imageMarked": {
    opacity: 0,
  },
  "&:hover .imageTitle": {
    border: "4px solid currentColor",
  },
  "& .imageTitle": {
    position: "relative",
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  "& .imageMarked": {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const images = [
  {
    url: drams,
    title: "Dramatics",
    width: "40%",
  },
  {
    url: lit,
    title: "literature",
    width: "25%",
  },
  {
    url: music,
    title: "Music",
    width: "35%",
  },
  {
    url: finearts,
    title: "fineArts",
    width: "25%",
  },
  {
    url: photo,
    title: "filmandphotography",
    width: "35%",
  },
  {
    url: dance,
    title: "dance",
    width: "40%",
  },
  // {
  //   url: "https://images.unsplash.com/photo-1506941433945-99a2aa4bd50a?auto=format&fit=crop&w=400",
  //   title: "Walking",
  //   width: "40%",
  // },
  // {
  //   url: "https://images.unsplash.com/photo-1533727937480-da3a97967e95?auto=format&fit=crop&w=400",
  //   title: "Fitness",
  //   width: "20%",
  // },
  {
    url: misc,
    title: "miscellaneousEvents",
    width: "30%",
  },
  {
    url: work,
    title: "Workshops",
    width: "35%"
  },
  {
    url: quiz,
    title: "Quiz",
    width: "35%"
  }
];

export default function EventsCategories() {
    const history = useHistory();
    const giveFullName = (eventType) => {
      switch (eventType){
        case "literature":
          return "Literature"
        case "fineArts":
          return "Fine Arts"
        case "filmandphotography":
          return "Film and Photography"
        case "dance":
          return "Dance"
        case "miscellaneousEvents":
          return "Miscellaneous Events"
        case "Quiz":
          return "Quiz Events"
        case "Workshops":
          return "Workshops(Edufabrica)"  
        default:
          return eventType
      }
  }

  const handleClick = (image)=>{
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 1);
    history.replace(`/events/${image.title}`);
   
  }
  return (
    <div id="events">
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography
        style={{ color: "white" }}
        variant="h4"
        marked="center"
        align="center"
        component="h2"
      >
        <b>
          <u>Our Events</u>
        </b>
      </Typography>
      <Box sx={{ mt: 8, display: "flex", flexWrap: "wrap" }}>
        {images.map((image) => (
          <ImageIconButton
          onClick={()=> handleClick(image)}
            key={image.title}
            style={{
              width: image.width,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: "cover",
                backgroundPosition: "center 40%",
                backgroundImage: `url(${image.url})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "common.white",
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="imageTitle"
              >
                {giveFullName(image.title)}
                <div className="imageMarked" />
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>
    </Container>
    </div>
  );
}
