import {useEffect} from "react";
import Navbar from "./Components/Navbar/Navbar";
import Main from "./Components/Main/Main";
import Footer from "./Components/Footer/Footer";



const MainEventsCardPage = ()=>{
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
          }, 1);
      }, []);
    return (
        <>
        <Navbar />
        <Main />
        <Footer />
        </>
    )
}

export default MainEventsCardPage;
