// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import Container from '@mui/material/Container';
// import Typography from '../Typography';
// import TextField from '../Textfield';
// import Snackbar from '../Snackbar';
// import Button from '../Button';
// import About from "../../images/About.jpg"

// function AboutUs() {
//   const [open, setOpen] = React.useState(false);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div id="aboutus">
//     <Container component="section" sx={{ mt: 10, display: 'flex' }}>
//       <Grid container>
//         <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'center',
//               bgcolor: '#FCC073',
//               py: 8,
//               px: 3,
//             }}
//           >
//             <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
//               <Typography variant="h4" component="h4" style={{fontFamily: "CenturyGothic"}} gutterBottom>
//                 <b>About Alma Fiesta</b>
//               </Typography>
//               <Typography  >
//               Alma Fiesta is the annual socio-cultural festival of the Indian Institute of Technology, Bhubaneswar. The 14th edition of Alma Fiesta comprising of competitions and performances from various cultural genres will be from 24th - 26th March this year. Based on the social theme of "Sainya Stuti" and the cultural theme of "Nautical Realm", team Alma is all set to give an unforgettable experience to its audience and participants. Go ahead and register yourself for the fest !!!
//               </Typography>
//               {/* <TextField
//                 noBorder
//                 placeholder="Your email"
//                 variant="standard"
//                 sx={{ width: '100%', mt: 3, mb: 2 }}
//               />
//               <Button
//                 type="submit"
//                 color="primary"
//                 variant="contained"
//                 sx={{ width: '100%' }}
//               >
//                 Keep me updated
//               </Button> */}
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
//         >
//           {/* <Box
//             sx={{
//               position: 'absolute',
//               top: 15,
//               left: -67,
//               right: 0,
//               bottom: 0,
//               width: '100%',
//               background: 'url(/static/themes/onepirate/productCTAImageDots.png)',
//             }}
//           /> */}
//           <Box
//             component="img"
//             src={About}
//             // src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"
//             alt="call to action"
//             sx={{
//               position: 'absolute',
//               top: 10,
//               left: -28,
//               right: 0,
//               bottom: 0,
//               width: '100%',
//               maxWidth: 600,
//             }}
//           />
//         </Grid>
//       </Grid>
//       <Snackbar
//         open={open}
//         closeFunc={handleClose}
//         message="We will send you our best offers, once a week."
//       />
//     </Container>
//     </div>
//   );
// }

// export default AboutUs;

import * as React from 'react';
import "./AboutUs.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../Typography';
import TextField from '../Textfield';
import Snackbar from '../Snackbar';
import Button from '../Button';
import About from "../../images/About.jpg"

function AboutUs() {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    
    <div id="aboutus" class="background-image"></div>
    
  );
}

export default AboutUs;