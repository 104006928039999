import React from "react";
import './home.css'
import surf from "../../images/surf.mp4";
import { Button } from "@mui/material";
import { displayErrorToast } from "../../Utils/ToastUtils";
import { useHistory } from 'react-router-dom';
// import NewLogo from "../../images/NewLogo.png"
import { useMediaQuery } from "@mui/material";

const Home = () => {

    const history = useHistory();
    const isDesktopOrLaptop = useMediaQuery("(min-width: 900px)");

    const handleClick = ()=>{
        window.location.assign("https://forms.gle/t7FxcvvxYx6x17Zo7");
        return;
        // if(!localStorage.getItem('jwt')){
        //     displayErrorToast("Please sign in first by going to navbar menu to continue....")
        //     return;
        // }
        // history.push("/payment");
        // return;
    }

    return (
        <section className="home" >
            {/* <div className="overlay"></div> */}
            <video src={surf} muted autoPlay loop poster="../images/vid_thumbnail.png" type="video/mp4" ></video>
            
            <div className="homeContent">
            </div>
            

        </section>
    )
}

export default Home;
