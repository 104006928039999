import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../Typography';
import TextField from '../Textfield';
import Snackbar from '../Snackbar';
import Button from '../Button';
import About from "../../images/About.jpg"

function AboutUs() {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div id="aboutus">
      <Container component="section" sx={{ mt: 10, display: 'flex' }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                bgcolor: '#FCC073',
                py: 8,
                px: 3,
              }}
            >
              <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
                <Typography variant="h4" component="h4" style={{fontFamily: "CenturyGothic"}} gutterBottom>
                  <b>Campus Ambassador</b>
                </Typography>
                <Typography  gutterBottom>
                Participate in our IIT Bhubaneswar Campus Ambassador Programme for Alma Fiesta, which is India's largest cultural and social event. Students with a strong sense of motivation can assist us in advancing campus culture, innovation, and social consciousness. As a Campus Ambassador, you will be able to contribute to the success of our event while simultaneously gaining useful skills and experiencing the culture of a diverse community.
                </Typography>
                <ul>
                <li><b>To join the program:</b> <a style={{textDecoration: true, color: "blue"}} href='https://forms.gle/USvDFarnmq7s5d2KA'>Click Here</a></li>
                </ul>
                {/* <TextField
                  noBorder
                  placeholder="Your email"
                  variant="standard"
                  sx={{ width: '100%', mt: 3, mb: 2 }}
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ width: '100%' }}
                >
                  Keep me updated
                </Button> */}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
          >
            {/* <Box
              sx={{
                position: 'absolute',
                top: 15,
                left: -67,
                right: 0,
                bottom: 0,
                width: '100%',
                background: 'url(/static/themes/onepirate/productCTAImageDots.png)',
              }}
            /> */}
            <Box
              component="img"
              src={About}
              // src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"
              alt="call to action"
              sx={{
                position: 'absolute',
                top: 15,
                left: -15,
                right: 0,
                bottom: 0,
                width: '110%',
                maxWidth: 600,
              }}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={open}
          closeFunc={handleClose}
          message="We will send you our best offers, once a week."
        />
      </Container>
      </div>
        {/* <form>
          <label>
            Name:
            <input type="text" name="name" />
          </label>
          <input type="submit" value="Submit" />
        </form> */}
    </div>
  );
}

export default AboutUs;