import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const SponsorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

const Sponsor = styled.div`
  width: 270px;
  height: 180px;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #f5f5f5;
  background-color: #0E0E0D; 
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  img {
    max-width: 80%;
    max-height: 80%;
  }
`;

const SponsorsPage = ({ sponsors }) => {
  return (
    <>
    { sponsors.map((spon,ind)=> (
      <>
      <div className="mt-2 d-flex align-items-center justify-content-center">
    <Typography  style={{color: "white",fontSize: "2.1rem"}} className="mb-1"><b><u>{spon.Type}</u></b></Typography>
    </div>
    <SponsorsWrapper>
      {spon.Details.map((sponsor, index) => (
        <Sponsor key={index}>
          <img src={sponsor.image} alt={sponsor.name} />
        </Sponsor>
      ))}
    </SponsorsWrapper>
    </>
    ))
}
    </>
  );
};

export default SponsorsPage;
