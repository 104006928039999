const EventsDetails = {
  literature: [
    {
      id: "literary-1",
      imageSrc:
        "https://images.unsplash.com/photo-1544531586-fde5298cdd40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "Reckoning",
      subtitle: "Short story writing",
      Location: "LHC",
      Day_Time: "16 March, 10AM-12:30PM",
      description:
        "Reckoning challenges participants to construct unique narratives by blending specific elements chosen from predetermined categories- character, genre, props and timeline to craft an original story",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      pos: "left",
      rulebook:
        "https://drive.google.com/file/d/1hCKT8Kaj0eXWg9feRcXw5wdjEIiS1sdZ/view?usp=drive_link",
    },

    {
      id: "literary-2",
      imageSrc:
        "https://www.readpoetry.com/wp-content/uploads/2019/04/oscar-keys-58399-unsplash.jpg",
      title: "KAVYODAY,(Hindi Poetry Slam)",
      subtitle: "Hindi Poetry Competition ",
      Location: "ONLINE",
      Day_Time: "17 March, 9:30AM-12PM",
      description:
        "Poetry Slam allows participants to display their literary skills through poetry and their oratory skills",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1dDoJm8s6caQhIS-Y_LDvuYaibCLgrK1w/view?usp=drive_link",
    },

    // {
    //   id: "literary-3",
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1614332625575-6bef549fcc7b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=841&q=80",
    //   title: "Vocabclash",
    //   subtitle: "Vocabulary competition",
    //   Location: "LBC-203",
    //   Day_Time: "25 March, 4PM ONWARDS",
    //   pos: "left",
    //   description:
    //     "Engage in intellectually stimulating and fun-filled battles in a highly competitive environment and test out your word skills and general knowledge",
    //   url: "https://forms.gle/PodyBwkwXaG6MSoA6",
    //   rulebook:
    //     "https://docs.google.com/document/d/1tKpQOsjbhzDZ78c0RyyoXFZB70fNv40pOeiHesqTy7g/edit?usp=sharing",
    // },

    {
      id: "literary-4",
      imageSrc:
        "https://images.unsplash.com/photo-1544531586-fde5298cdd40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "Debattle",
      subtitle: "Debating Competition in online mode",
      Location: "LHC",
      Day_Time: "16 March, 2:30PM-5PM",
      description:
        "The ultimate battle of wits. An electrifying 2V2 online debate battle",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1PuHSVuSdn4ZBfb5r8cI6HwbpDM7ZjqPk/view?usp=drive_link",
    },

    // {
    //   id: "literary-5",
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1472457897821-70d3819a0e24?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",
    //   title: "Metamorphosis",
    //   subtitle: "",
    //   Location: "ONLINE",
    //   pos: "left",
    //   Day_Time: "26 March, 3PM ONWARDS",
    //   description:
    //     "Showcase your creativity by updating a character in the past and placing him in the modern world to invent a new and exciting life",
    //   url: "https://forms.gle/PodyBwkwXaG6MSoA6",
    //   rulebook:
    //     "https://docs.google.com/document/d/1z4xUnzV1X_2i459fO2obpc-7hjWjFPJg0bWV_3FEK5U/edit?usp=sharing",
    // },
  ],
  Dramatics: [
    {
      id: "dramatics-1",
      imageSrc:
        "https://images.unsplash.com/photo-1617575521317-d2974f3b56d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      title: "Face Off",
      subtitle: "Stage play",
      Location: "Auditorium",
      Day_Time: "17 March, 10:00AM-12PM",
      description:
        "A stage play reflects the ideas of the script writer, expression of the actors and music of music supervisor. ",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1wZ1EzBoSqoSBiCJEJNceimLdW9Ypno2H/view?usp=drive_link",
    },

    {
      id: "dramatics-2",
      pos: "left",
      imageSrc:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Christopher_Walken_-_1984.jpg/440px-Christopher_Walken_-_1984.jpg",
      title: "Spotlight",
      subtitle: "Monologue Competition",
      Location: "Community Centre",
      Day_Time: "17 March, 2:30PM-5PM",
      description:
        "Spotlight, a monologue event giving you this opportunity to enhance your talent in front of others and make your long lasting impression.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1fEbuD4MA-Ct-44-zltvW10dF34fzYlru/view?usp=drive_link",
    },

    {
      id: "dramatics-3",
      imageSrc:
        "https://upload.wikimedia.org/wikipedia/commons/4/4e/Rehearsal_on_the_Stage.jpg",
      title: "Nukkad",
      subtitle: "Street Play Competition",
      Location: "BB-Court",
      Day_Time: "16 March, 4:30PM",
      description:
        "Nukkad is a street play event to gain public attention and convey a message or act.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1hh5QyCe9hbTNejxQPnGgR8Jx5zNcymtv/view?usp=drive_link",
    },

    // {
    //   id: "dramatics-4",
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1611673982064-7385a5d9574e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    //   title: "Encircled",
    //   subtitle: "",
    //   Location:"L-Gate",
    //   Day_Time:"Day-1, 10AM-1PM",
    //   description:
    //     "",
    //   url: "https://forms.gle/PodyBwkwXaG6MSoA6",
    //   rulebook:
    //     "",
    // },
  ],
  Music: [
    {
      id: "music-1",
      imageSrc:
        "https://images.unsplash.com/photo-1563902244988-42d466e79b25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "Euphony",
      subtitle: "Solo or Band Performance",
      Location: "Auditorium",
      pos: "left",
      Day_Time: "16 March, 3PM",
      description:
        "It is a solo or band competition. The participants can play any number of songs of any type of music and genre within the time limit- 20 mins. They will be judged based on their song selection, originality, synchronization, beat sense, individual skills and overall impact.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/10l85UseiG8tr67s1HRIbrJZQdS-GWRrv/view?usp=drive_link",
    },

    {
      id: "music-2",
      imageSrc:
        "https://images.unsplash.com/photo-1594214456448-9ca134a5d5d7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      title: "Unplugged",
      subtitle: "Solo Singing",
      Location: "LHC",
      Day_Time: "16 March, 10AM",
      description:
        "It is a solo singing competition.The vocalists can sing only one song of their choice in the time limit- 3 minutes.They will be judged based on their song selection, beat sense, rhythm and their creativity.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1D2Hs7OZV_J1fxeuaSXjRqRDXDr0W9yHw/view?usp=drive_link",
    },
  ],
  miscellaneousEvents: [
    // {
    //   id: "miscellaneous_Event-1",
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1563902244988-42d466e79b25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    //   title: "Antakshari",
    //   pos: "left",
    //   Location: "BASKETBALL COURT",
    //   Day_Time: "25 March 3PM-7PM",
    //   subtitle: "Musical competition to test your musical knowledge",
    //   description:
    //     "The all-time favourite antakshari where you can showcase your knowledge in the musical world and showcase some melodious singing.",
    //   url: "https://forms.gle/PodyBwkwXaG6MSoA6",
    //   rulebook:
    //     "https://docs.google.com/document/d/1cTJTlIaCOdavLuTvWWw_kw4nyVvCeY0TUxx3iDc7A04/edit?usp=sharing",
    // },
    {
      id: "miscellaneous_Event-2",
      imageSrc:
        "https://images.unsplash.com/photo-1588217885773-2f27c9c3b1c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=395&q=80",
      title: "ESCAPE ROOM",
      subtitle: "Escape the room using clues",
      Location: "SAC",
      Day_Time: "BOTH DAYS",
      description:
        "This competition involves using the clues given to the contestants to escape the room they are trapped in.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1mvKgNVLCkvbieu-G367C_eicM-1t7Szm/view?usp=drive_link",
    },

    {
      id: "miscellaneous_Event-3",
      imageSrc:
        "https://images.unsplash.com/photo-1612257998531-70e0d0a15f6d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      title: "TREASURE HUNT",
      subtitle: "",
      pos: "left",
      Location: "SAC",
      Day_Time: "BOTH DAYS",
      description:
        "The object of the Hunt is to have fun. It is a game in which people try to find a hidden prize by following special signs (clues) which have been left in different places.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1bAF1_tMe_OcrrFm17IKOcNLLl1OWEJxo/view?usp=drive_link",
    },

    // {
    //   id: "miscellaneous_Event-4",
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1604928106847-96c6f306b722?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    //   title: "RAINDANCE",
    //   subtitle: "",
    //   Location:"HOCKEY COURT",
    //   Day_Time:"25 March 11AM-3PM",
    //   description:
    //     "It is fun event with not much specific rules",
    //   url: "https://forms.gle/PodyBwkwXaG6MSoA6",
    //   rulebook:
    //     "",
    // },

    // {
    //   id: "miscellaneous_Event-5",
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1505527385992-63e06a393342?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80",
    //   title: "REEL IT",
    //   subtitle:
    //     "A reel making competition to portray your creativity in any form to the wider public through us",
    //   Location: "ONLINE",
    //   pos: "left",
    //   Day_Time: "BOTH DAYS",
    //   description:
    //     "A reel making competition to portray your creativity in any form to the wider public through us",
    //   url: "https://forms.gle/PodyBwkwXaG6MSoA6",
    //   rulebook:
    //     "https://docs.google.com/document/d/15wM15Mghx6Bh4Z_jiieajJ5rFHCqEv45LdqhQSzbjSQ/edit?usp=sharing",
    // },
  ],

  fineArts: [
    {
      id: "fine_arts-1",
      imageSrc:
        "https://mymodernmet.com/wp/wp-content/uploads/2021/09/face-painting-ideas-kids-2.jpg",
      title: "Naqaab-Face Painting Competition",
      subtitle: "Naqaab",
      Location: "BASKETBALL COURT",
      Day_Time: "17 March, 5PM",
      description:
        "Naqaab means having your other side of character rather than you as a real person. It's a face\
    painting event in which you are allowed to paint your face while following all the given rules.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1hoWClUCwnG13J__HG-eIJES35UjENvaM/view?usp=drive_link",
    },

    // {
    //   id: "fine_arts-2",
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1585923972216-f30dc61fd61c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    //   title: "Shades",
    //   pos: "left",
    //   subtitle: "Pencil Sketching Competition",
    //   Location: "SAC",
    //   Day_Time: "16 March, 3PM",
    //   description:
    //     "A shades is a sketching event, where candidates have to sketch some art (no specific theme is\
    //   applied) It is a time-based event where participants have to sketch their art in two\
    //   hours(submission after time will not be allowed).\
    //   ",
    //   url: "https://forms.gle/PodyBwkwXaG6MSoA6",
    //   rulebook:
    //     "https://drive.google.com/file/d/1ATDOBM1Jr_P9hLLBQ9_UHlogOcYUkaRG/view?usp=drive_link",
    // },

    {
      id: "fine_arts-3",
      imageSrc:
        "https://images.unsplash.com/photo-1642091335544-7d26e1ecd9e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "Monochrome",
      subtitle: "Single Colour Painting Event",
      Location: "SAC",
      Day_Time: "16 March, 3PM",
      description:
        "A monochrome or monochromatic painting is one created using only one color or hue. It can\
    use different shades of one color but by definition should contain only one base color.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1ATDOBM1Jr_P9hLLBQ9_UHlogOcYUkaRG/view?usp=drive_link",
    },
  ],

  filmandphotography: [
    {
      id: "photography-1",
      imageSrc:
        "https://images.unsplash.com/photo-1621500734231-1afa223987df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
      title: "Broll making",
      subtitle: "Broll making competition",
      description:
        "A short film making contest to portray your creativity in any form to the wider public through us",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/17BcSsCW8ga2tA9GUh-owJOeM45jBNqkx/view?usp=drive_link",
    },

    {
      id: "photography-2",
      imageSrc:
        "https://images.unsplash.com/photo-1590486803833-1c5dc8ddd4c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      title: "Cityscape Chronicles",
      subtitle: "Photography Competition",
      Location: "ONLINE",
      pos: "left",
      description:
        "Attend the events of Alma Fiesta, click pictures and post it on your instagram stories tagging @almafiesta.iitbbs The best 3 clicks of each day will be shared on our instagram story.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1QcNkOtYtj2DdsgnnHSIBGGwl-SOY-bYX/view?usp=drive_link",
    },
  ],
  dance: [
    {
      id: "dance-1",
      imageSrc:
        "https://images.unsplash.com/photo-1546427660-eb346c344ba5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      title: "Rip Out",
      subtitle: "Solo Dance",
      Location: "CC",
      Day_Time: "16 March, 10AM-1PM",
      description:
        "It is a Solo Dance Competition, open to all dance forms be it contemporary, hip hop, classical, Bollywood, or any other.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1Zjj9dQluPurdx4hExTkx5d9hqp3r2g9s/view?usp=drive_link",
    },

    {
      id: "dance-2",
      pos: "left",
      imageSrc:
        "https://images.unsplash.com/photo-1551186839-9e4a19b27812?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZGFuY2UlMjBiYXR0bGV8ZW58MHx8MHx8&w=1000&q=80",
      title: "Dance Battle",
      subtitle: "Solo/Group Dance Face-off",
      Location: "Basketball Court",
      Day_Time: "17 March, 5:30PM-7:30PM",
      description:
        "An event where each round will be a face-off between two competing teams where participants will have to spontaneously dance for the music played..",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1tZM0BNfdtaJf63NOufj_XQz1Z6tdZmHQ/view?usp=drive_link",
    },

    {
      id: "dance-3",
      imageSrc:
        "https://images.unsplash.com/photo-1621976498727-9e5d56476276?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "Topsy Turvy",
      subtitle: "Group dance",
      Location: "Auditorium",
      Day_Time: "17 March, 2:30PM-5PM",
      description:
        "It is a group dance competition, so find a group of your friends with whom you sync the best and dance your way out to glory.",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1lEX5KanYS-ggcXKwWlP-Iyi3HBLsaxm1/view?usp=drive_link",
    },
  ],

  Quiz: [
    {
      id: "Quiz-1",
      imageSrc:
        "https://images.unsplash.com/photo-1539628399213-d6aa89c93074?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "MELA QUIZ",
      subtitle: "A quiz competition to test your knowledge",
      Location: "LHS",
      pos: "left",
      Day_Time: "16 March, 10AM",
      description:
        "A quiz competition to test your knowledge in different domains by answering questions in the limited time available",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/1snIDljEARJ0XQmN4bDEWODNVS5IsM5Qv/view?usp=drive_link",
    },

    {
      id: "Quiz-2",
      imageSrc:
        "https://images.unsplash.com/photo-1539628399213-d6aa89c93074?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      title: "THEME QUIZ",
      subtitle: "A quiz competition to test your knowledge",
      Location: "LHS",
      pos: "left",
      Day_Time: "16 March, 1:30PM",
      description:
        "A quiz competition to test your knowledge in different domains by answering questions in the limited time available",
      url: "https://forms.gle/PodyBwkwXaG6MSoA6",
      rulebook:
        "https://drive.google.com/file/d/13mXJCXMS8j8uaSazrw7ViDbKAh6lAd6S/view?usp=drive_link",
    },
  ],
  Workshops: [
    {
      id: "workshop",
      imageSrc:
        "https://images.unsplash.com/photo-1563770660941-20978e870e26?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      title: "Edufabrica Offline Technical Workshop",
      Location: "IIT Bhubaneswar",
      Day_Time: "Apr 1, 10AM to Apr 2, 5PM",
      description:
        "This is an edufabrica Offline Technical Workshop Series with Alma Fiesta, IIT Bhubaneswar - 2023. You will get more details about workshops after clicking on details button or register button.",
      rulebook:
        "https://hpsummer.myinstamojo.com/product/3735774/edufabrica-offline-workshop-series-with-alma/",
    },
    {
      id: "workshop",
      imageSrc:
        "https://images.unsplash.com/photo-1583912086296-be5b665036d3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80",
      title: "Edufabrica Offline Biological Workshop",
      Location: "IIT Bhubaneswar",
      pos: "left",
      Day_Time: "April 2023",
      description:
        "This is an edufabrica Offline Biological Workshop Series with Alma Fiesta, IIT Bhubaneswar - 2023. You will get more details about workshops after clicking on details button or register button.",
      rulebook:
        "https://hpsummer.myinstamojo.com/product/3735770/edufabrica-offline-biological-workshop-serie-96a79/",
    },
  ],
};

export default EventsDetails;
