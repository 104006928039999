import React from 'react';
import './profile.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useState } from 'react';
import axios from 'axios';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdb-react-ui-kit';
import EditProfileModal from './EditProfileModal';
import { useContext, useEffect } from 'react';
import { AccountContext } from '../../Context/AccountProvider';
import { useHistory } from 'react-router-dom';
import { displayErrorToast, displayInfoToast } from '../../Utils/ToastUtils';
import Loader from '../Loader';
import Button from '../Button';
import { displaySuccessToast } from '../../Utils/ToastUtils';
// import initPayment

const Profile = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { account, setAccount } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('jwt')) {
      displayInfoToast('Please first sign in to continue!..');
      history.push('/');
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const token = window.localStorage.getItem('jwt');
        const Response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/user/getuser`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setAccount(Response.data.user);
        // console.log(Response.data.user);
        if (
          Response.status === 200 &&
          Response.data.user.isRegistrationComplete === false
        ) {
          displayInfoToast('Please Provide your Remaining Details....');
          setShowProfileModal(true);
        }
        setLoading(false);
      } catch (err) {
        if (
          err.response &&
          String(err.response.data.error.statusCode).startsWith('4')
        ) {
          return displayErrorToast(err.response.data.message);
        }
        displayErrorToast('There was some error! Please try again later');
      }
    })();
  }, []);

  const getEventType = (inp) => {
    // switch (inp) {
    //   case 'fine_arts-1':
    //     return fineArts;
    //   case 'fine_arts-2':
    //     return fineArts;
    //   case 'fine_arts-3':
    //     return fineArts;
    //   case 'literary-1':
    //     return literature;
    //   case 'literary-2':
    //     return literature;
    //   case 'literary-3':
    //     return literature;
    //   case 'literary-4':
    //     return literature;
    //   case 'literary-5':
    //     return literature;
    //   case 'literary-6':
    //     return literature;
    // }
    for (let i=1;i<7;i++){
      if (inp===`litratary-${i}`){
        return "literature"
      }
    }
    for (let i=1;i<3;i++){
      if (inp===`dramatics-${i}`){
        return "Dramatics"
      }
    }
    for (let i=1;i<5;i++){
      if (inp===`music-${i}`){
        return "Music"
      }
    }
    for (let i=1;i<4;i++){
      if (inp===`miscellaneous_Event-${i}`){
        return "miscellaneousEvents"
      }
    }
    for (let i=1;i<4;i++){
      if (inp===`fine_arts-${i}`){
        return "fineArts"
      }
    }
    for (let i=1;i<4;i++){
      if (inp===`filmandphotography-${i}`){
        return "filmandphotography"
      }
    }
    for (let i=1;i<5;i++){
      if (inp===`dance-${i}`){
        return "dance"
      }
    }
  };

  // console.log(account);
  const toCheckout = () => {
    history.push(`/payment`)
  };

  const checkOrg = (email) => {
    const index = email.indexOf("@");
    const domain = email.substr(index);
    if (domain !== "@iitbbs.ac.in") return false;
    return true;
  };

  const getRegistrationStatus = ()=>{
    if(checkOrg(account.email)){
      return "Registered";
    }
    if(account.paymentStatusForAccommodation === true){
      return "Registered with Accommodation";
    }
    else if(account.PaymentStatusForEvents === true){
      return "Registered";
    }
    else{
      return "Not Registered yet complete your payment";
    }
  }

  return !account ? (
    <Loader />
  ) : (
    <>
      <Navbar />
      <section>
        <MDBContainer className='py-5'>
          <MDBRow>
            <MDBCol>
              <MDBBreadcrumb className='bg-light rounded-3 p-3 mb-4 d-flex justify-content-center'>
                <MDBBreadcrumbItem
                  style={{ fontFamily: 'CenturyGothic', fontSize: '1.5rem' }}
                >
                  Welcome to Alma Fiesta
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol lg='4'>
              <MDBCard className='mb-4'>
                <MDBCardBody className='text-center'>
                  <MDBCardImage
                    src={
                      account.image
                        ? account.image
                        : 'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp'
                    }
                    alt='avatar'
                    className='rounded-circle'
                    style={{ width: '150px' }}
                    fluid
                  />
                  <p className='text-muted mb-3 mt-3'>
                    {account.name ? account.name : 'Candidate Name'}
                  </p>
                  {/* <p className="text-muted mb-4">Bay Area, San Francisco, CA</p> */}
                  <div className='d-flex justify-content-center mb-2'>
                    {!account.isRegistrationComplete && (
                      <MDBBtn onClick={() => setShowProfileModal(true)}>
                        Edit Profile
                      </MDBBtn>
                    )}
                    {/* <MDBBtn outline className="ms-1">Message</MDBBtn> */}
                  </div>
                </MDBCardBody>
              </MDBCard>

              <MDBCard className='mb-4 mb-lg-0'>
                {/* <MDBCardBody className="p-0">
                <MDBListGroup flush className="rounded-3">
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fas icon="globe fa-lg text-warning" />
                    <MDBCardText>College Name: IIT Bhubaneswar</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="github fa-lg" style={{ color: '#333333' }} />
                    <MDBCardText>mdbootstrap</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="twitter fa-lg" style={{ color: '#55acee' }} />
                    <MDBCardText>@mdbootstrap</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="instagram fa-lg" style={{ color: '#ac2bac' }} />
                    <MDBCardText>mdbootstrap</MDBCardText>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <MDBIcon fab icon="facebook fa-lg" style={{ color: '#3b5998' }} />
                    <MDBCardText>mdbootstrap</MDBCardText>
                  </MDBListGroupItem>
                </MDBListGroup>
              </MDBCardBody> */}
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol sm='6'>
                      <MDBCardText>College Name: </MDBCardText>
                    </MDBCol>
                    <MDBCol sm='6'>
                      <MDBCardText className='text-muted'>
                        {account.institute ? account.institute : '-'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='6'>
                      <MDBCardText>Your Branch </MDBCardText>
                    </MDBCol>
                    <MDBCol sm='6'>
                      <MDBCardText className='text-muted'>
                        {account.branch ? account.branch : '-'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='6'>
                      <MDBCardText>Year of Study</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='6'>
                      <MDBCardText className='text-muted'>
                        {account.year ? account.year : '-'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  {/* <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Phone</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">(097) 234-5678</MDBCardText>
                  </MDBCol>
                </MDBRow> */}
                  {/* <hr /> */}
                  {/* <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">(098) 765-4321</MDBCardText>
                  </MDBCol>
                </MDBRow> */}
                  {/* <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>College Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">IIT Bhubaneswar</MDBCardText>
                  </MDBCol>
                </MDBRow> */}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol lg='8'>
              <MDBCard className='mb-4'>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Full Name</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText className='text-muted'>
                        {account.name ? account.name : 'Candidate Name'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Your Alma ID</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText className='text-muted'>
                        {account.almaID ? account.almaID : 'Your id here'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Email</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText className='text-muted'>
                        {account.email ? account.email : 'Your email here'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Phone</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText className='text-muted'>
                        {account.phoneNumber
                          ? account.phoneNumber
                          : 'Your phone number here'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  {/* <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">(098) 765-4321</MDBCardText>
                  </MDBCol>
                </MDBRow> */}
                  {/* <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText className="pt-1" >Registration Status</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <div className="d-flex align-items-center">
                      <MDBCardText className='text-muted pt-1'>
                        {account? getRegistrationStatus() : "-" }
                      </MDBCardText>
                      {
                        !checkOrg(account.email) && !account.PaymentStatusForEvents && !account.paymentStatusForAccommodation && <Button onClick={toCheckout} className="mb-1" variant="contained" size="small">Register</Button>
                      }
                      </div>
                    </MDBCol>
                  </MDBRow> */}
                </MDBCardBody>
              </MDBCard>

              <MDBRow>
                <MDBCol md='12'>
                  <MDBCard className='mb-4 mb-md-0'>
                    <MDBCardBody>
                      <MDBCardText className='mb-4'>
                        <span className='text-primary font-italic me-1'>
                          Registered Events
                        </span>
                      </MDBCardText>
                      <ol>
                        {account?.registrations.map((event) => {
                          return <li className='mt-3'>{event.name}</li>;
                        })}
                      </ol>
                      {/* <MDBCardText
                        className="mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Web Design
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={80}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Website Markup
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={72}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        One Page
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={89}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Mobile Template
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={55}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Backend API
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={66}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress> */}
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>

                {/* <MDBCol md='6'>
                  <MDBCard className='mb-4 mb-md-0'>
                    <MDBCardBody>
                      <MDBCardText className='mb-4'>
                        <span className='text-primary font-italic me-1'>
                          Partially Registered
                        </span>
                        (Payment Pending)
                      </MDBCardText>
                      <ul>
                        {account?.partialRegistrations.map((event) => {
                          return (
                            <div className='d-flex justify-content-between align-items-center mt-3 '>
                              <li>{event.name}</li>
                              <Button
                                size='small'
                                variant='contained'
                                onClick={() => toCheckout(event)}
                              >
                                Pay
                              </Button>
                            </div>
                          );
                        })}
                      </ul> */}
                      {/* <MDBCardText className="mb-1" style={{ fontSize: '.77rem' }}>Web Design</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={80} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Website Markup</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={72} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>One Page</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={89} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Mobile Template</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                    </MDBProgress>

                    <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Backend API</MDBCardText>
                    <MDBProgress className="rounded">
                      <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                    </MDBProgress> */}
                    {/* </MDBCardBody>
                  </MDBCard>
                </MDBCol> */}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <EditProfileModal
          showModal={showProfileModal}
          setShowModal={setShowProfileModal}
        />
      </section>
      <Footer />
    </>
  );
};

export default Profile;
