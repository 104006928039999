import T_1 from  "../images/Titile/title_logo.png"
import P_1 from "../images/Platinum/P_1.png"
import P_2 from "../images/Platinum/P_2.png"
import D_1 from "../images/Diamond/D_1.PNG"
import D_2 from "../images/Diamond/D_2.png"
import G_1 from "../images/Gold/G_1.jpg"
import G_2 from "../images/Gold/G_2.png"
import G_3 from "../images/Gold/G_3.png"
import G_4 from "../images/Gold/G_4.png"
import S_1 from "../images/Silver/S_1.jpg"
import S_2 from "../images/Silver/S_2.png"
import S_3 from "../images/Silver/S_3.png"
import E_1 from "../images/Event/E_1.png"
import E_2 from "../images/Event/E_2.jpeg"
import F_1 from "../images/Food/F_1.png"
import F_2 from "../images/Food/F_2.png"
import F_3 from "../images/Food/F_3.png"
import F_4 from "../images/Food/F_4.jpeg"




export const SponsorsDetails = [
    {
        Type: "Title Sponsor",
        cann: true,
        Details: [
            {
                name: "MelpApp",
                image: T_1
            }
        ]
    },
    {
        Type: "Platinum Sponsor",
        cann: false,
        Details: [
            {
                name: "",
                image: P_1
            },
            {
                name: "Canara Bank",
                image: P_2
            }
        ]
    },
    {
        Type: "Diamond Sponsor",
        cann: false,
        Details: [
            {
                name: "AHP Productions",
                image: D_1
            },
            {
                name: "Shyam Steel",
                image: D_2
            }
        ]
    },
    {
        Type: "Gold Sponsor",
        cann: false,
        Details: [
            {
                name: "State Bank of India",
                image: G_4
            },
            {
                name: "Oil India Limited",
                image: G_1
            },
            {
                name: "Imfa",
                image: G_2
            },
            {
                name: "My City Odisha",
                image: G_3
            },
        ]
    },
    {
        Type: "Silver Sponsor",
        cann: false,
        Details: [
            {
                name: "NPCIL",
                image: S_1
            },
            {
                name: "idco",
                image: S_2
            },
            {
                name: "Quriate",
                image: S_3
            }
        ]
    },
    {
        Type: "Event Partners",
        cann: false,
        Details: [
            {
                name: "Athena Music Academy",
                image: E_1
            },
            {
                name: "Dance Studio",
                image: E_2
            }
        ]
    },
    {
        Type : "Food Partners",
        cann: false,
        Details: [
            {
                name: "Adda cafe",
                image: F_1
            },
            {
                name: "Biryani Box",
                image: F_2
            },
            {
                name: "Shake speare",
                image: F_3
            },
            {
                name: "Bekdows",
                image: F_4
            }
        ]
    }
]