import React, { useState } from "react";
import "./main.css";
import EventsDetails from "../../data/EventsDetails";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { RiRegisteredLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import EventCardModal from "../EventsCard/EventsCardModal";
import Modal from "@mui/material/Modal";
import styles from "../EventsCard/EventCard.module.css";
import { Typography } from "@mui/material";

const Main = () => {
  const { eventType } = useParams();
  const [open, setOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  const handleOpen = (event) => {
    setOpen(true);
    setCurrentEvent(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (open) {
    return (
      <Modal
        style={{
          overflow: "scroll",
        }}
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modalDiv} onClick={handleClose}>
          <EventCardModal
            event={{
              ...currentEvent,
              rules: ["Rule 1", "Rule 2", "Rule 3", "Rule 4"],
              criteria: ["Criteria 1", "Criteria 2", "Criteria 3", "Criteria 4"],
              contact: "XYZ - 9998889999",
            }}
          />
        </div>
      </Modal>
    );
  }

  const giveFullName = (eventType) => {
    switch (eventType) {
      case "literature":
        return "Literature";
      case "fineArts":
        return "Fine Arts";
      case "filmandphotography":
        return "Film and Photography";
      case "dance":
        return "Dance";
      case "miscellaneousEvents":
        return "Miscellaneous Events";
      default:
        return eventType;
    }
  };

  return (
    <section className="main container section">
      <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4 d-flex justify-content-center">
        <MDBBreadcrumbItem
          style={{ fontFamily: "CenturyGothic", fontSize: "1.5rem" }}
        >
          Our {giveFullName(eventType)} Events
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <div className="secContent grid">
        {EventsDetails[`${eventType}`].map((event) => {
          return (
            <div className="singleDestination">
              <div className="imageDiv">
                <img alt="img" src={event.imageSrc} />
              </div>
              <div className="cardInfo">
                <h4 className="destTitle">{event.title}</h4>
                <div className="d-flex align-items-center ">
                  <div className="price d-flex justify-content-center align-items-center mb-1">
                    <Typography>LOCATION: {event.Location}</Typography>
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <div className="price d-flex justify-content-center align-items-center mb-2">
                    <Typography>TIME: {event.Day_Time}</Typography>
                  </div>
                </div>
                <div className="desc">
                  <p>{event.description}</p>
                </div>
                <button
                  onClick={() => window.location.assign(`${event.rulebook}`)}
                  className="btn flex"
                >
                  DETAILS <HiOutlineClipboardCheck className="icon" />
                </button>
                <button
                  onClick={() => window.open("https://forms.gle/PodyBwkwXaG6MSoA6", "_blank")}
                  className="btn flex ms-4"
                >
                  REGISTER <RiRegisteredLine className="icon" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Main;
