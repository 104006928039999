import "./ourTeam.css"
import styled from "styled-components";
import {useState,useEffect} from 'react';
import {Link,Stack} from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
  margin: 2rem;
  width: 300px;
  height: 450px;
  backdrop-filter: blur(4px);
  background-color: rgba(0,0,0,0.3);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  overflow: hidden;
`;

const Name = styled.h3`
  margin: 0.5rem 0 0;
  color: white;
`;

const Email = styled.p`
  margin: 0.25rem 0;
  color: white;
`;

const Phone = styled.p`
  margin: 0.25rem 0;
  color: white;
`;

const linkConvert = (link) => {
  const id = link.slice(33, 66);
  return `https://drive.google.com/thumbnail?id=${id}`
}



const OurTeamPage = () => {

  const [data,setData]=useState();

  const getData = async ()=>{
    // console.log(process.env.SHEET_API);
     try{
         const res = await fetch(
          'https://sheet.best/api/sheets/d922794a-04ba-437b-95ee-f7c78159e52f'
         );
         const data = await res.json();
        
         setData(data);
         console.log(data);
     }
     catch(err){
      console.log(err);
     }
  }

  useEffect(()=>{
    getData();
  },[]);


  // data?.map(dt=>dt.img=`https://drive.google.com/uc?id=${dt.img.slice(33,65)}`);
  // console.log(data);
   const events = data?.filter(dt=>dt.Team==="Events");
   const webD = data?.filter(dt=>dt.Team==="Webd_3d");
   const publi = data?.filter(dt=>dt.Team==="Publi");
   const spons = data?.filter(dt=>dt.Team==="Spons");
   const chiko = data?.filter(dt=>dt.Team==="chiko");
  //  const ddd = data?.filter(dt=>dt.Team=="3D");
  //  console.log(events);


  return (
    <div
      style={{
        padding: "6rem 0",
      }}
    >
      <h2 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        Our Team
      </h2>
      <h4 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        Chief Coordinator
        {/* {console.log(process.env.SHEET_API)} */}
      </h4>
      {/* <Image src={} alt={dt.Name} /> */}
      <Grid>
        {chiko?.map((dt) => (
          <Card key={dt.Email}>
            <Image src={linkConvert(dt.img)} alt={dt.Name} />
            <Name>{dt.Name}</Name>
            <Email>{dt.Email}</Email>
            <Phone>{dt.ContactNumber}</Phone>
            <Stack spacing={3} direction='row'>
              <Link href={dt.InstagramId}><InstagramIcon/></Link>
              <Link href={dt.LinkedInId}><LinkedInIcon/></Link>
              <Link href={dt.FacebookId}><FacebookIcon/></Link>
            </Stack>

          </Card>
        ))}
      </Grid>
      <h4 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        Coordinators
      </h4>
      <h4 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        Events Team
      </h4>
      <Grid>
        {events?.map((dt) => (
          <Card key={dt.Email}>
            {/* {console.log(linkConvert(dt.img))} */}
            <Image src={linkConvert(dt.img)} alt={dt.Name} />
            <Name>{dt.Name}</Name>
            <Email>{dt.Email}</Email>
            <Phone>{dt.ContactNumber}</Phone>
            <Stack spacing={3} direction='row'>
              <Link href={dt.InstagramId}><InstagramIcon/></Link>
              <Link href={dt.LinkedInId}><LinkedInIcon/></Link>
              <Link href={dt.FacebookId}><FacebookIcon/></Link>
            </Stack>

          </Card>
        ))}
      </Grid>
      <h4 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        WebD and 3D Team
      </h4>
      <Grid>
        {webD?.map((dt) => (
          <Card key={dt.Email}>
            <Image src={linkConvert(dt.img)} alt={dt.Name} />
            <Name>{dt.Name}</Name>
            <Email>{dt.Email}</Email>
            <Phone>{dt.ContactNumber}</Phone>
            <Stack spacing={3} direction='row'>
              <Link href={dt.InstagramId}><InstagramIcon/></Link>
              <Link href={dt.LinkedInId}><LinkedInIcon/></Link>
              <Link href={dt.FacebookId}><FacebookIcon/></Link>
            </Stack>
          </Card>
        ))}
      </Grid>
      <h4 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        Publicity Team
      </h4>
      <Grid>
        {publi?.map((dt) => (
          <Card key={dt.Email}>
            <Image src={linkConvert(dt.img)} alt={dt.Name} />
            <Name>{dt.Name}</Name>
            <Email>{dt.Email}</Email>
            <Phone>{dt.ContactNumber}</Phone>
            <Stack spacing={3} direction='row'>
              <Link href={dt.InstagramId}><InstagramIcon/></Link>
              <Link href={dt.LinkedInId}><LinkedInIcon/></Link>
              <Link href={dt.FacebookId}><FacebookIcon/></Link>
            </Stack>
          </Card>
        ))}
      </Grid>
      <h4 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        Sponsorship Team
      </h4>
      <Grid>
        {spons?.map((dt) => (
          <Card key={dt.Email}>
            <Image src={linkConvert(dt.img)} alt={dt.Name} />
            <Name>{dt.Name}</Name>
            <Email>{dt.Email}</Email>
            <Phone>{dt.ContactNumber}</Phone>
            <Stack spacing={3} direction='row'>
              <Link href={dt.InstagramId}><InstagramIcon/></Link>
              <Link href={dt.LinkedInId}><LinkedInIcon/></Link>
              <Link href={dt.FacebookId}><FacebookIcon/></Link>
            </Stack>
          </Card>
        ))}
      </Grid>
      {/* <h4 style={{ marginBottom: "2rem", color: "white", textAlign: "center" }}>
        3D Team
      </h4>
      <Grid>
        {ddd?.map((dt) => (
          <Card key={dt.Email}>
            <Image src={linkConvert(dt.img)} alt={dt.Name} />
            <Name>{dt.Name}</Name>
            <Email>{dt.Email}</Email>
            <Phone>{dt.ContactNumber}</Phone>
            <Stack spacing={3} direction='row'>
              <Link href={dt.InstagramId}><InstagramIcon/></Link>
              <Link href={dt.LinkedInId}><LinkedInIcon/></Link>
              <Link href={dt.FacebookId}><FacebookIcon/></Link>
            </Stack>
          </Card>
        ))}
      </Grid> */}
    </div>
  )
}

export default OurTeamPage;