import React from 'react';
import bgLeft from '../images/how_to_reach_left.jpg';
import bgRight from '../images/how_to_reach_right.jpg';

import './howtoreach.css';

function HowToReach() {
  const mapContainerStyle = {
    // border: '2px solid #000', // Adds a border around the map for visual distinction
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Optional: Adds a shadow for depth
    //margin: '20px 0', // Adds top and bottom margin for spacing from other elements
    overflow: 'hidden', // Ensures nothing overflows from the container
    borderRadius: '8px',
    height: '400px',
    width: '50%',
    minWidth: '300px',
  };

  return (

    <div style={{
      display: "flex",
      flexDirection: "row",
      marginBottom: '2rem',
      width: '100%',
    }}>

      <div className='left' style={{
        backgroundImage: `url(${bgLeft})`,
        backgroundSize: 'cover'
        // align the bg image to cover the entire div

      }}>

         <div style={mapContainerStyle}>
          <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3745.6576839300355!2d85.66906071409568!3d20.14832392262704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19ac2e52a057f5%3A0x564b6e83a7504a16!2sIndian%20Institute%20Of%20Technology%20(IIT)%20Bhubaneswar!5e0!3m2!1sen!2sin!4v1679372279359!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
           ></iframe>
        </div>

      </div>

      <div className='right'>

      </div>

    </div>
  );
}

export default HowToReach;


