import React from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";


function ShippingDelivery(){
    return (
        <>
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4 mt-4 d-flex justify-content-center">
        <MDBBreadcrumbItem
          style={{ fontFamily: "CenturyGothic", fontSize: "1.5rem" }}
        >
          Shipping & Delivery Policy
        </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4 mt-4 d-flex justify-content-center">
        <MDBBreadcrumbItem
          style={{ fontFamily: "CenturyGothic", fontSize: "1rem" }}
        >
          <b><u>Last updated on Oct 13th 2022:   </u></b>

          For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 3-5 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. Alma Fiesta is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 3-5 days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on 7380778657 or deepak26071976@gmail.com
        </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        
        </>
    )
}

export default ShippingDelivery;