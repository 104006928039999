import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";

export default function Details({account}) {
  return (
    <React.Fragment>
      <Typography className='d-flex justify-content-center mb-3' variant="h5" >
        Your Details
      </Typography>
      <MDBCardBody>
                  <MDBRow>
                    <MDBCol sm="4">
                      <MDBCardText>Full Name</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="8">
                      <MDBCardText className="text-muted">
                      {account.name?account.name:"Candidate Name"}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="4">
                      <MDBCardText>Your Alma ID</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="8">
                      <MDBCardText className="text-muted">
                      {account.almaID?account.almaID:"Your id here"}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="4">
                      <MDBCardText>Email</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="8">
                      <MDBCardText className="text-muted">
                      {account.email?account.email:"Your email here"}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="4">
                      <MDBCardText>Phone</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="8">
                      <MDBCardText className="text-muted">
                      {account.phoneNumber?account.phoneNumber:"Your phone number here"}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  {/* <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">(098) 765-4321</MDBCardText>
                  </MDBCol>
                </MDBRow> */}
                  <MDBRow>
                    <MDBCol sm="4">
                      <MDBCardText>College Name</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="8">
                      <MDBCardText className="text-muted">
                      {account.institute?account.institute:"-"}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
        {/* <Grid item xs={12} md={12}>
          <div className="d-flex align-items-center justify-content-around">
            <div>
                Name: 
            </div>
            <div>
                {user?user.name:"Your Name Here"}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="d-flex align-items-center justify-content-around">
            <div>
                Email: 
            </div>
            <div>
            {user?user.email:"Your Email Here"}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="d-flex align-items-center justify-content-around">
            <div>
                Alma ID: 
            </div>
            <div>
            {user?user.almaID:"Your Alma ID Here"}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="d-flex align-items-center justify-content-around">
            <div>
                Phone Number: 
            </div>
            <div>
            {user?user.phoneNumber:"Your Phone Number Here"}
            </div>
          </div>
        </Grid> */}
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}
    </React.Fragment>
  );
}