import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Review from './Review';
import Details from './Details';
import axios from "axios";
import { useContext, useEffect,useState } from "react";
import { AccountContext } from "../../Context/AccountProvider";
import { useHistory, useParams } from "react-router-dom";
import { displayErrorToast, displayInfoToast,displaySuccessToast } from "../../Utils/ToastUtils";
import Loader from "../Loader";
import EventsDetails from "../../data/EventsDetails.js"
import oldLogo from "../../images/oldLogo.png"

// function Copyright() {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const steps = ['Your Details', 'Payment'];

const theme = createTheme();

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const { account, setAccount } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  // const {event, subEventId} = useParams();
  // const subEventDetails = EventsDetails[event].filter((el)=> el.id === subEventId);
  // console.log(subEventDetails);
  const [orderId, setOrderId] = useState("");
  const [paymentType, setPaymentType] = React.useState("1");

  const checkOrg = (email) => {
    const index = email.indexOf("@");
    const domain = email.substr(index);
    if (domain !== "@iitbbs.ac.in") return false;
    return true;
  };

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      displayInfoToast("Please first sign in to continue!..");
      history.push("/");
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const token = window.localStorage.getItem("jwt");
        const Response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/user/getuser`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        setAccount(Response.data.user);
        if (
          Response.status === 200 &&
          Response.data.user.isRegistrationComplete === false
        ) {
          displayInfoToast("Please Provide your Remaining Details....");
          history.push("/profile");
          return;
        }
        else if (
          Response.status === 200 &&
          (Response.data.user.paymentStatusForAccommodation === true || Response.data.user.PaymentStatusForEvents === true)
        ) {
          displayInfoToast("You have already registered with alma fiesta...");
          history.push("/");
          return;
        }
        setLoading(false);
      } catch (err) {
        if (
          err.response &&
          String(err.response.data.error.statusCode).startsWith("4")
        ) {
          return displayErrorToast(err.response.data.message);
        }
        displayErrorToast("There was some error! Please try again later");
      }
    })();
  }, []);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Details account={account} />;
      case 1:
        return <Review paymentType={paymentType} setPaymentType={setPaymentType} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePaymentSuccess = async (response) => {
    try {
      const token = window.localStorage.getItem("jwt");
      const Response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user/paymentsuccess`,
        {
          paymentConfirmation: true,
          id: paymentType,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      if (Response.status === 200) {
        displaySuccessToast(`Payment Successful`, { autoClose: 2000 });
        handleNext();
      }
    } catch (err) {
      if (err.response) {
        return displayErrorToast(err.response.data.message);
      }
      displayErrorToast("There was some error! Please try again later or contact us");
    }
  };

  const initPayment = async (order) => {
    
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("There was some error");
      return;
    }

    const options = {
      key: process.env.RAZORPAY_KEY_ID, //keyid
      amount: order.amount,
      currency: order.currency,
      order_id: order.id,
      name: "Alma 2023",
      description: "Event Registration",
      image: oldLogo,
      handler: async (response) => {
        try {
          response.order_id = order.id;
          response.id = paymentType;
          const verifyUrl = `${process.env.REACT_APP_BASE_URL}/api/payment/verifyOrder`;
          const { data } = await axios.post(verifyUrl, response, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            withCredentials: true,
          });
          // console.log(order);

          if (data && data.signal === true) {
            // make a post request to axios to save the user in the database
            
            if(paymentType === 1){
            displaySuccessToast("Your Payment is Successfull...You are successfully registered for alma fiesta",{ autoClose: 2000 });
            }
            else{
              displaySuccessToast("Your Payment is Successfull...You are successfully registered for alma fiesta and also for accommdation");
            }
            // handlePaymentSuccess(response);
            setOrderId(order.id);
            handleNext();
          }
        } catch (error) {
          displayErrorToast("Your Payment is unsuccessful, if amount is deducted, Please contact us..")
          console.log(error.response.data);
        }
      },
    };

    const payment = new window.Razorpay(options);
    payment.open();
  };

  const handlePayment = async () => {
    if(checkOrg(account?.email)){
      displayInfoToast("All events are free for IITBBS Students...");
      return;
    }
    try {
      const orderUrl = `${process.env.REACT_APP_BASE_URL}/api/payment/createOrder`;
      const { data } = await axios.post(
        orderUrl,
        { id: paymentType },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
          withCredentials: true,
        }
      );
      initPayment(data.order);
    } catch (err) {
      if (err.response) {
        return displayErrorToast(err.response.data.message);
      }
      displayErrorToast("There was some error! Please try again later or contact us");
    }
  };

  return (!account?<Loader />:(<>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Company name
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Checkout (Please Register using Laptop)
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is {orderId}. We will email your order
                confirmation, and will send you an complete information!
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={activeStep === steps.length - 1?handlePayment:handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Pay' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        {/* <Copyright /> */}
      </Container>
    </ThemeProvider>
    </>
  )
  );
}