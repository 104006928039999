import React, { useEffect, useState } from "react";
import "./app.css";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Main from "./Components/Main/Main";
import EventsCategories from "./Components/EventsCategories/EventsCategories";
import Footer from "./Components/Footer/Footer";
import Profile from "./Components/Dashboard/Profile";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Checkout from "./Components/Checkout/Checkout";
import OurTeam from "./Components/OurTeam/OurTeam";
import AboutUs from "./Components/AboutUs/AboutUs";
import CampusAmb from "./Components/CA/CampusAmb";
import SchedulePage from "./Components/Schedule/SchedulePage";
import MainEventsCardPage from "./MainEventsCardPage";
import SponsorsPage from "./Components/Our Sponsors/SponsorsPage";
import OurTeamPage from "./Components/Our Team/OurTeamPage";
import AlumniPage from "./Components/Our Alumni/AlumniPage";
import Tejas from "../src/images/Tejas.jpg"
import Modi from "../src/images/Modi.jpg"
import Anshul from "./images/Anshul.jpeg";
import CircularStatic from "./CircularProgress";
import Privacy from "./Components/razorpay_pages/PrivacyPolicy.jsx";
import ContactUs from "./Components/razorpay_pages/ContactUs";
import CancellationRefundPolicy from "./Components/razorpay_pages/CancellationRefundPolicy";
import ShippingDelivery from "./Components/razorpay_pages/ShippingDelivery";
import Terms from "./Components/razorpay_pages/Terms";
import Pricing from "./Components/Pricing/Pricing";
import HowToReach from "./Components/HowToReach";
import { SponsorsDetails } from "./data/SponsorsDetails";
import curse from './images/cursor.png';

const contacts = [
  {
    name: "Shubh Modi",
    email: "events.almafiesta@iitbbs.ac.in",
    phone: "9415424717",
    image: Modi,
  },
  {
    name: "Tejas Singh ",
    email: "coord.almafiesta@iitbbs.ac.in",
    phone: "6392012669",
    image: Tejas,
  },
  {
    name: "Anshul Sahu",
    email: "events.almafiesta@iitbbs.ac.in",
    phone: "9039840298",
    image: Anshul,
  },
];

const App = () => {

  const [loading,setLoading] = useState(false);

  // useEffect(()=>{
  //   setLoading(true);
  // },[]);

  return loading?<div className="d-flex align-items-center justify-content-center"><CircularStatic value={100} setLoading={setLoading} ></CircularStatic></div>:(
    <>
      <Router>
        <Switch>
          {/* <Route path="/events/:type">
            <Events />
          </Route> */}
          {/* <Route path="/team-page">j
            <TeamPage />
          </Route> */}
          {/* <Route path="/additional-info">
            <AdditionalPage />
          </Route> */}
          <Route path="/profile">
            <>
              {/* <Navbar /> */}
              <Profile />
              {/* <Footer /> */}
            </>
          </Route>
          <Route exact path="/">
            <div style={{width:"100%", cursor:curse}}>
            {/* <CircularStatic value={100}/> */}
            {/* {console.log(process.env)} */}
            <div className="appContainer">
              <Navbar classname="navbar" />
              <Home classname="home" />
            </div>
              <AboutUs  />
              <Pricing />
              <EventsCategories />
              <HowToReach />
              <Footer />
            </div>
          </Route>
          <Route exact path="/events/:eventType">
            <MainEventsCardPage />
          </Route>
          <Route exact path="/payment">
            <Navbar />
            <Checkout />
            <Footer />
          </Route>
          <Route exact path="/contactus">
            <>
              <Navbar />
              <OurTeam contacts={contacts} />
              <Footer />
            </>
          </Route>
          <Route exact path="/schedule">
            <>
              <Navbar />
              <SchedulePage />
              <Footer />
            </>
          </Route>
          <Route path="/sponsors">
            <>
              <Navbar />
              <SponsorsPage  sponsors={SponsorsDetails} />
              <Footer />
            </>
          </Route>
          <Route path="/campusAmb">
            <>
              <Navbar />
              <CampusAmb />
              <Pricing />
              <Footer />
            </>
          </Route>
          <Route path="/ourTeam">
            <>
              <Navbar />
              <OurTeamPage />
              <Footer />
            </>
          </Route>
          {/* <Route path="/alumniPage">
            <>
              <Navbar />
              <AlumniPage />
              <Footer />
            </>
          </Route> */}
          <Route exact path="/privacy-policy" >
            <>
            <Navbar />
            <Privacy />
            <Footer />
            </>
          </Route>
          <Route exact path="/contact-us" >
            <>
            <Navbar />
            <ContactUs />
            <Footer />
            </>
          </Route>
          <Route exact path="/terms-and-conditions" >
            <>
            <Navbar />
            <Terms />
            <Footer />
            </>
          </Route>
          <Route exact path="/shipping-delivery" >
            <>
            <Navbar />
            <ShippingDelivery />
            <Footer />
            </>
          </Route>
          <Route exact path="/cancellation-and-refund" >
            <>
            <Navbar />
            <CancellationRefundPolicy />
            <Footer />
            </>
          </Route>
        </Switch>
        <ToastContainer transition={Slide} theme="colored" limit={1} />
      </Router>
    </>
  );
};

export default App;
