import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Toast } from "react-bootstrap";
import { useContext } from "react";
import { AccountContext } from "../../Context/AccountProvider";
import { displayErrorToast, displaySuccessToast } from "../../Utils/ToastUtils";

export default function EditProfileModal(props) {
  const { showModal, setShowModal} = props;
  const {setAccount} = useContext(AccountContext);

  const handleCreateFormSubmit = async(
    PhoneNumber,
    CollegeName,
    Branch,
    Year,
    RefrenceID,
  ) => {
    setShowModal(false);

    try {
        const token = window.localStorage.getItem("jwt");

        const Response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/user/finish-registration`,
          {
            phoneNumber: PhoneNumber,
            institute: CollegeName,
            branch: Branch,
            year: Year,
            ReferralID: RefrenceID,
            isRegistrationComplete: true
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        if (Response.status === 200) {
            setAccount(Response.data.user);
            displaySuccessToast("Your Details are successfully updated");
            setShowModal(false);
            window.location.reload();
            return;
        }
      } catch (err) {
        if (err.response) {
          return Toast.error(err.response.data.message);
        }
        displayErrorToast("There was some error! Please try again later");
      }
  }

  const schema = yup.object().shape({
    PhoneNumber: yup.number().required("Please enter a valid Phone Number"),
    CollegeName: yup.string().required("Please enter your College"),
    Branch: yup.string(),
    Year: yup.string(),
    RefrenceID: yup.string(),
  });

  return (
    <Modal
      contentClassName="modalStyle"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <h3 className="ms-2 mt-3">Edit Your Profile Details</h3>
      <div className="form-group mt-3 ms-2">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            handleCreateFormSubmit(
              values.PhoneNumber,
              values.CollegeName,
              values.Branch,
              values.Year,
              values.RefrenceID
            );
          }}
          initialValues={{
            PhoneNumber: '',
            CollegeName: '',
            Branch: '',
            Year: '',
            RefrenceID: '',

          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form
              className="mt-4 mx-1 me-2"
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group controlId="PhoneNumber">
                <Form.Label>Your Phone Number:</Form.Label>
                <Form.Control
                  type="number"
                  value={values.PhoneNumber}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 mb-2 bg-light"
                  isInvalid={!!errors.PhoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.PhoneNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="CollegeName">
                <Form.Label>Your College Name:</Form.Label>
                <Form.Control
                  type="college name"
                  value={values.CollegeName}
                  placeholder="eg . IIT Bhubaneswar"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 mb-2 bg-light"
                  isInvalid={!!errors.CollegeName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.CollegeName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="Branch">
                <Form.Label>Your Branch: </Form.Label>
                <Form.Control
                  type="branch"
                  value={values.Branch}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 mb-2 bg-light"
                  isInvalid={!!errors.Branch}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Branch}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="Year">
                <Form.Label>Year of Study:</Form.Label>
                <Form.Control
                  type="Year"
                  value={values.Year}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 bg-light"
                  isInvalid={!!errors.Year}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Year}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="RefrenceID">
                <Form.Label>Refrence ID of CA(if any):</Form.Label>
                <Form.Control
                  type="RefrenceID"
                  value={values.RefrenceID}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 bg-light"
                  isInvalid={!!errors.RefrenceID}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.RefrenceID}
                </Form.Control.Feedback>
              </Form.Group>

              <Modal.Footer>
                <Button
                  className="border-0 rounded-4 bg-secondary"
                  type="submit"
                >
                 Update Details
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}